import { Feedback } from "./feedback";
import * as _ from "lodash";
import { AthleteDetails, CoachDetails, User } from "./user";
import { accessMedia, Avatar } from "./avatar";
import { assignCoach, CoachingAssignment, CoachingType } from "./assignment";
import { RelationshipInvitation } from "./invitation";
import {
    CoachingSession,
    CoachingSessionSeries,
    getAllSessionsForAssignment,
    SessionOrSeriesInstance,
} from "./session";
import { AthleteSubscriptionEvent, ChurnType } from "./subscription-event";
import { byDate } from "../util";
import { Context, createContext, useCallback, useState } from "react";
import { run } from "./api";
import { AssessmentSubmission } from "./athlete";
import { useNavigate } from "react-router-dom";
import { Note } from "./note";
import { adminGetNotes, getUserFeedback } from "./admin";
import { AppleIAPEvent } from "./iap";
import { subMonths } from "date-fns";

export type InvitationWithInviter = RelationshipInvitation & { inviter: User };

type AdminAthleteAssignmentResponse = CoachingAssignment & {
    coach: CoachDetails & {
        user: User & {
            avatar?: Avatar;
        };
    };
    sessions: CoachingSession[];
    series: CoachingSessionSeries[];
};

export type AdminAthleteParent = {
    parent: User & {
        avatar?: Avatar;
    };
};

export type AdminAthleteResponse = AthleteDetails & {
    user?: User & {
        avatar?: Avatar;
        parents: AdminAthleteParent[];
        submittedAssessments: AssessmentSubmission[];
        appleIAPEvents: AppleIAPEvent[];
    };
    subscriptionEvents: AthleteSubscriptionEvent[];
    relationshipInvitations: InvitationWithInviter[];
    coachingAssignments: AdminAthleteAssignmentResponse[];
};

type AdminAthlete = {
    id?: string;
    firstName: string;
    lastName: string;
    email?: string;
    avatarUrl?: string;
    athleteDetails: AthleteDetails;
    invitationCode?: string;
    adminTestUser: boolean;
};

export type AdminAthleteCoachAssignedEvent = {
    type: "coach_assigned";
    coachingType: CoachingType;
    date: Date;
    coachUser: User;
    assignment: CoachingAssignment;
    series: CoachingSessionSeries[];
    upcomingSessions: SessionOrSeriesInstance[];
    pastSessions: SessionOrSeriesInstance[];
};

export type AdminAthleteChurnEvent = {
    type: "churned";
    coachingType: CoachingType;
    date: Date;
    churnType?: ChurnType;
};

export type AdminAthleteAssignmentEvent = AdminAthleteCoachAssignedEvent | AdminAthleteChurnEvent;

export type AdminAthleteState = {
    user?: AdminAthlete;
    invitations: InvitationWithInviter[];
    assignmentEvents: AdminAthleteAssignmentEvent[];
    parents: User[];
    assessmentSubmissions: AssessmentSubmission[];
    notes: Note[];
    feedback: Feedback[];
    appleIAPEvents: AppleIAPEvent[];
};

function parseSubscriptionEvent(event: AthleteSubscriptionEvent): AdminAthleteChurnEvent {
    return {
        type: "churned",
        coachingType: event.discipline,
        date: event.createdAt,
        churnType: event.churnType,
    };
}

async function parseAssignmentEvent(
    response: AdminAthleteAssignmentResponse,
): Promise<AdminAthleteCoachAssignedEvent> {
    const fromDate = subMonths(new Date(), 120);
    const allSessions = await getAllSessionsForAssignment(response.id, fromDate);
    const now = new Date();
    return {
        type: "coach_assigned",
        coachingType: response.coachingType,
        coachUser: response.coach.user,
        date: response.createdAt,
        assignment: response,
        series: response.series,
        upcomingSessions: allSessions.filter(session => session.date > now),
        pastSessions: allSessions.filter(session => session.date < now),
    };
}

async function parseResponse(response: AdminAthleteResponse): Promise<AdminAthleteState> {
    const invitation = response.relationshipInvitations.find(i => i.inviteeType === "athlete");
    const assignmentEvents = await Promise.all(
        response.coachingAssignments.map(parseAssignmentEvent),
    );
    const subscriptionEvents = response.subscriptionEvents.map(parseSubscriptionEvent);
    const invitationParents = response.relationshipInvitations
        .map(invitation => invitation.inviter)
        .filter(u => u.type === "parent");
    const assignedParents = await Promise.all(
        response.user?.parents?.map(async (parent: AdminAthleteParent) => ({
            ...parent.parent,
            avatarUrl: parent.parent.avatar?.mediaId
                ? await accessMedia(parent.parent.avatar?.mediaId)
                : undefined,
        })) ?? [],
    );
    return {
        user: {
            id: response.user?.id,
            firstName: response.user?.firstName ?? invitation?.firstName ?? "(No first name)",
            lastName: response.user?.lastName ?? invitation?.lastName ?? "(No last name)",
            email: response.user?.email ?? invitation?.inviteeEmail,
            avatarUrl: response.user?.avatar
                ? await accessMedia(response.user.avatar.mediaId)
                : undefined,
            athleteDetails: response,
            invitationCode: invitation?.accepterId ? undefined : invitation?.code,
            adminTestUser: response.user?.adminTestUser ?? false,
        },
        invitations: response.relationshipInvitations,
        assignmentEvents: [...assignmentEvents, ...subscriptionEvents].sort(byDate),
        parents: _.uniqBy([...invitationParents, ...assignedParents], parent => parent.id),
        assessmentSubmissions: response.user?.submittedAssessments ?? [],
        notes: [],
        feedback: [],
        appleIAPEvents: response.user?.appleIAPEvents ?? [],
    };
}

export async function getAdminAthlete(athleteId: string): Promise<AdminAthleteResponse> {
    return run({ path: `/admin/athletes/${athleteId}`, method: "GET" });
}

async function getAthlete(athleteId: string): Promise<AthleteDetails> {
    return await run({ path: `/athletes/${athleteId}`, method: "GET" });
}

async function addDiscipline(athleteId: string, coachingType: CoachingType): Promise<void> {
    const interests = (await getAthlete(athleteId)).coachingInterests;
    const coachingInterests = [...interests, coachingType];
    await run({
        path: `/athletes/${athleteId}`,
        method: "PATCH",
        body: { coachingInterests },
    });
}

async function removeDiscipline(athleteId: string, coachingType: CoachingType): Promise<void> {
    const interests = (await getAthlete(athleteId)).coachingInterests;
    const coachingInterests = interests.filter(i => i !== coachingType);
    await run({
        path: `/athletes/${athleteId}`,
        method: "PATCH",
        body: { coachingInterests },
    });
}

async function churn(
    athleteId: string,
    discipline: CoachingType,
    assignmentId: string,
    churnType?: ChurnType,
): Promise<void> {
    await run({
        path: `/athletes/${athleteId}/subscription-events`,
        method: "POST",
        body: {
            athleteId,
            type: "churned",
            discipline,
            assignmentId,
            churnType,
        },
    });
}

const initialState: AdminAthleteState = {
    user: undefined,
    invitations: [],
    assignmentEvents: [],
    parents: [],
    assessmentSubmissions: [],
    notes: [],
    feedback: [],
    appleIAPEvents: [],
};

export type AdminAthleteAssignCoachAction = {
    type: "assign_coach";
    athleteId: string;
    coachId: string;
    coachingType: CoachingType;
};

export type AdminAthleteAssignParentAction = {
    type: "assign_parent";
    childId: string;
    parentId: string;
};

export type AdminAthleteAddDisciplineAction = {
    type: "add_discipline";
    coachingType: CoachingType;
};

export type AdminAthleteRemoveDisciplineAction = {
    type: "remove_discipline";
    coachingType: CoachingType;
};

export type AdminAthleteChurnAction = {
    type: "churn";
    coachingType: CoachingType;
    assignmentId: string;
    churnType: ChurnType;
};

export type AdminAthleteDeleteInvitationAction = {
    type: "delete_invitation";
    code: string;
};

export type AdminAthleteRefreshAction = {
    type: "refresh";
};

type AdminAthleteAction =
    | AdminAthleteRefreshAction
    | AdminAthleteAssignCoachAction
    | AdminAthleteAssignParentAction
    | AdminAthleteAddDisciplineAction
    | AdminAthleteRemoveDisciplineAction
    | AdminAthleteChurnAction
    | AdminAthleteDeleteInvitationAction;

export function useAdminAthlete(
    athleteId: string,
): [AdminAthleteState, (action: AdminAthleteAction) => void] {
    const [state, setState] = useState(initialState);
    const navigate = useNavigate();
    const dispatch = useCallback(
        async action => {
            switch (action.type) {
                case "refresh":
                    try {
                        const response = await getAdminAthlete(athleteId);
                        const state = await parseResponse(response);
                        if (state.user?.id) {
                            state.notes = await adminGetNotes(state.user.id);
                            state.feedback = await getUserFeedback(state.user.id);
                        }
                        setState(state);
                    } catch (e) {
                        console.log("Failed to getAdminAthlete", e);
                    }
                    break;
                case "add_discipline":
                    await addDiscipline(athleteId, action.coachingType);
                    await dispatch({ type: "refresh" });
                    break;
                case "remove_discipline":
                    await removeDiscipline(athleteId, action.coachingType);
                    await dispatch({ type: "refresh" });
                    break;
                case "churn":
                    await churn(
                        athleteId,
                        action.coachingType,
                        action.assignmentId,
                        action.churnType,
                    );
                    await dispatch({ type: "refresh" });
                    break;
                case "assign_coach":
                    await assignCoach(action.athleteId, action.coachId, action.coachingType);
                    await dispatch({ type: "refresh" });
                    break;
                case "assign_parent":
                    await run({
                        path: `/users/${action.childId}/parents`,
                        method: "POST",
                        body: {
                            parentId: action.parentId,
                            type: "parent",
                        },
                    });
                    await dispatch({ type: "refresh" });
                    break;
                case "delete_invitation":
                    await run({ path: `/invitations/${action.code}`, method: "DELETE" });
                    navigate("/admin");
                    break;
            }
        },
        [athleteId, navigate],
    );
    return [state, dispatch];
}

export const AdminAthleteContext: Context<
    [AdminAthleteState, (action: AdminAthleteAction) => void]
> = createContext([initialState, _ => {}]);
