import { CoachingAssignment, CoachingType } from "./assignment";

type SubscriptionEventType = "churned";
export type ChurnType = "cancel" | "inactive" | "completedCoaching" | "completedRenewal";
export type AthleteSubscriptionEvent = {
    id: string;
    athleteId: string;
    discipline: CoachingType;
    type: SubscriptionEventType;
    assignment?: CoachingAssignment;
    createdAt: Date;
    churnType?: ChurnType;
};

export function churnDisplayText(churnType: ChurnType): string {
    switch (churnType) {
        case "cancel":
            return "Canceled";
        case "inactive":
            return "Inactive";
        case "completedCoaching":
            return "Completed Coaching";
        case "completedRenewal":
            return "Completed Renewal";
    }
}
