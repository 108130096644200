import { AdminAthleteDetailsSectionHeader } from "./AdminAthleteDetailsHeader";
import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SendbirdContext } from "../../../vendor/sendbird";
import { UserAvatarRow } from "../../shared/AvatarRow";
import { byDate, coachingTypeText } from "../../../util";
import { compareDesc } from "date-fns";
import { confirmationText, seriesDescription, sessionDescription } from "../../../data/session";
import { CoachingType } from "data/assignment";
import { Modal } from "components/shared/Modal";
import {
    AdminAthleteChurnEvent,
    AdminAthleteCoachAssignedEvent,
    AdminAthleteContext,
    AdminAthleteState,
} from "../../../data/admin-athlete";
import { AssignCoach } from "./AssignCoach";
import { churnDisplayText, ChurnType } from "data/subscription-event";

function AdminAthleteDetailsCoachRow({
    coach,
    actionButtons,
}: {
    coach: AdminAthleteCoachAssignedEvent;
    actionButtons?: JSX.Element;
}) {
    const navigate = useNavigate();
    const [, sendbirdDispatch] = useContext(SendbirdContext);
    const goToMessaging = () => {
        sendbirdDispatch({ type: "malkovich", userId: coach.coachUser.id });
        navigate(`/messaging/${coach.assignment.id}`);
    };
    return (
        <>
            <i>
                {coach.coachUser.firstName} {coach.coachUser.lastName} assigned on{" "}
                {coach.date.toLocaleDateString()}
            </i>
            <Link to={`/admin/coaches/${coach.assignment.coachId}`}>
                <UserAvatarRow
                    userId={coach.coachUser.id}
                    title={`${coach.coachUser.firstName} ${coach.coachUser.lastName}`}
                    showChevron
                />
            </Link>
            <div className="admin-athlete-coach-row">
                <button className="btn" onClick={() => goToMessaging()}>
                    Go to messaging
                </button>
                {coach.assignment.status === "active" && (
                    <div className="d-flex flex-row assignment-options">{actionButtons}</div>
                )}
                <h4>Series</h4>
                <ul className="no-li-style" style={{ marginLeft: "20px" }}>
                    {coach.series
                        .filter(s => s.status === "active")
                        .map(series => (
                            <li key={series.id}>{seriesDescription(series)}</li>
                        ))}
                </ul>
                <h4>Upcoming Sessions ({coach.upcomingSessions.length})</h4>
                <ul className="no-li-style" style={{ marginLeft: "20px" }}>
                    {coach.upcomingSessions
                        .sort((lhs, rhs) => compareDesc(lhs.date, rhs.date))
                        .map(session => (
                            <li key={session.id}>{sessionDescription(session)}</li>
                        ))}
                </ul>
                <h4>Past Sessions ({coach.pastSessions.length})</h4>
                <ul className="no-li-style" style={{ marginLeft: "20px" }}>
                    {coach.pastSessions
                        .sort((lhs, rhs) => compareDesc(lhs.date, rhs.date))
                        .map(session => (
                            <li key={session.id}>
                                {sessionDescription(session)}
                                <div style={{ marginLeft: "20px" }}>
                                    {confirmationText(session)}
                                </div>
                            </li>
                        ))}
                </ul>
                <br />
            </div>
        </>
    );
}

function AdminAthleteDetailsChurnedRow({ event }: { event: AdminAthleteChurnEvent }) {
    return (
        <div>
            <i>
                Marked as churned on {event.date.toLocaleDateString()}
                {event.churnType && <span> ({churnDisplayText(event.churnType)}) </span>}
            </i>
            <br />
        </div>
    );
}

function getActiveAssignment(
    state: AdminAthleteState,
    coachingType: CoachingType,
): AdminAthleteCoachAssignedEvent | undefined {
    return state.assignmentEvents.find(
        event =>
            event.type === "coach_assigned" &&
            event.assignment.coachingType === coachingType &&
            event.assignment.status === "active",
    ) as AdminAthleteCoachAssignedEvent;
}

function ChurnModal({
    show,
    onClose,
    onConfirm,
}: {
    show: boolean;
    onClose: () => void;
    onConfirm: (churnType: ChurnType) => void;
}) {
    const [reason, setReason] = useState<ChurnType>("cancel");
    const churnTypes = [
        "cancel",
        "inactive",
        "completedCoaching",
        "completedRenewal",
    ] as ChurnType[];
    return (
        <Modal show={show} hide={onClose}>
            <div className="churn-modal-content d-flex flex-col" style={{ gap: "0.75rem" }}>
                <h2>Mark this client as churned</h2>
                <div className="d-flex flex-col" style={{ gap: "0.5rem" }}>
                    <label>What's the reason for this churn?</label>
                    <select value={reason} onChange={e => setReason(e.target.value as ChurnType)}>
                        {churnTypes.map(churn => (
                            <option value={churn}>{churnDisplayText(churn)}</option>
                        ))}
                    </select>
                </div>
                <div>
                    <button className="btn btn-lg" onClick={() => onConfirm(reason)}>
                        Confirm
                    </button>
                    <button className="btn btn-lg" onClick={onClose}>
                        Cancel
                    </button>
                </div>
            </div>
        </Modal>
    );
}

function DisciplineSection({ coachingType }: { coachingType: CoachingType }) {
    const [showConfirmChurn, setShowConfirmChurn] = useState(false);
    const [showAssignCoach, setShowAssignCoach] = useState(false);
    const [adminAthleteState, adminAthleteDispatch] = useContext(AdminAthleteContext);
    const activeAssignment = getActiveAssignment(adminAthleteState, coachingType);
    const hasInterest =
        adminAthleteState.user?.athleteDetails?.coachingInterests.includes(coachingType);

    function addDiscipline() {
        adminAthleteDispatch({
            type: "add_discipline",
            coachingType,
        });
    }

    function removeDiscipline() {
        adminAthleteDispatch({
            type: "remove_discipline",
            coachingType,
        });
    }

    function markAsChurned(churnType: ChurnType) {
        if (activeAssignment) {
            adminAthleteDispatch({
                type: "churn",
                coachingType,
                assignmentId: activeAssignment.assignment.id,
                churnType,
            });
        }
        setShowConfirmChurn(false);
    }

    const actionButtons: JSX.Element = (
        <div>
            <button className="text-link" onClick={() => setShowAssignCoach(true)}>
                Assign a different coach
            </button>
            |
            <button className="text-link" onClick={removeDiscipline}>
                Remove Discipline
            </button>
            |
            <button className="text-link" onClick={() => setShowConfirmChurn(true)}>
                Mark as Churned
            </button>
        </div>
    );

    const modal: JSX.Element = (
        <ChurnModal
            show={showConfirmChurn}
            onClose={() => setShowConfirmChurn(false)}
            onConfirm={markAsChurned}
        />
    );

    const addDisciplineButton: JSX.Element = (
        <div>
            <button className="text-link" onClick={addDiscipline}>
                Add this discipline
            </button>
        </div>
    );

    const assignCoachButton: JSX.Element = (
        <div>
            <button className="text-link" onClick={() => setShowAssignCoach(true)}>
                Assign a Coach
            </button>
        </div>
    );

    const removeDisciplineButton: JSX.Element = (
        <div>
            <button className="text-link" onClick={removeDiscipline}>
                Remove Discipline
            </button>
        </div>
    );

    return (
        <div className="admin-athlete-discipline-selector">
            <u>{coachingTypeText(coachingType)}</u>
            {modal}
            {showAssignCoach && (
                <AssignCoach coachingType={coachingType} hide={() => setShowAssignCoach(false)} />
            )}
            <div style={{ marginBottom: 0, paddingBottom: 0 }}>
                {adminAthleteState.assignmentEvents
                    .filter(event => event.coachingType === coachingType)
                    .sort(byDate)
                    .map(event => {
                        switch (event.type) {
                            case "coach_assigned":
                                return (
                                    <AdminAthleteDetailsCoachRow
                                        key={event.date.toString()}
                                        coach={event}
                                        actionButtons={actionButtons}
                                    />
                                );
                            case "churned":
                                return (
                                    <AdminAthleteDetailsChurnedRow
                                        key={event.date.toString()}
                                        event={event}
                                    />
                                );
                            default:
                                return <></>;
                        }
                    })}
            </div>
            {!hasInterest && addDisciplineButton}
            {hasInterest && !activeAssignment && !showAssignCoach && (
                <div>
                    {assignCoachButton}
                    {removeDisciplineButton}
                </div>
            )}
        </div>
    );
}

export function AdminAthleteDetailsCoaches() {
    return (
        <div className="admin-athlete-content-container">
            <AdminAthleteDetailsSectionHeader text="Disciplines" />
            <hr />
            <DisciplineSection coachingType="mindset" />
            <DisciplineSection coachingType="nutrition" />
        </div>
    );
}
